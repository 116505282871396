"use client";

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import * as RadixSlider from "@radix-ui/react-slider";
import { TooltipArrow } from "@radix-ui/react-tooltip";
import { ChevronLeft, ChevronRight, TriangleIcon } from "lucide-react";
import { useRouter, useSearchParams } from "next/dist/client/components/navigation";
import qs from "qs";
import React, { ComponentRef, useEffect, useRef, useState } from "react";
import { useScenarioSlider } from "./ScenarioSliderProvider";
export default function PotentialValueSlider({
  alwaysShowTooltip = false
}: {
  alwaysShowTooltip?: boolean;
}) {
  const router = useRouter();
  const {
    currentIndex,
    setCurrentIndex,
    defaultIndex,
    debouncedIndex,
    slideSteps,
    ticker
  } = useScenarioSlider();
  const steps = slideSteps?.steps ?? [];
  const lowIndex = slideSteps?.lowIndex ?? 0;
  const highIndex = slideSteps?.highIndex ?? steps.length - 1;
  const [isMounted, setIsMounted] = useState(false);
  let ref = useRef<ComponentRef<typeof RadixSlider.Root>>(null);
  useEffect(() => {
    setTimeout(() => {
      // hack to prevent ref dependent calculations from running on initial render
      setIsMounted(true);
    }, 10);
  }, []);
  const [_, setWindowWidth] = useState(0);

  // Does not directly affect the width, but is used to trigger a re-render when the window is resized
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const {
    width
  } = ref.current?.getBoundingClientRect() ?? {
    width: 0
  };
  const value = currentIndex || currentIndex === 0 ? steps[currentIndex] : undefined;
  const leftPercenatage = 0.025;
  const leftMinPx = width * leftPercenatage;
  const percentagePx = width * (1 - 2 * leftPercenatage);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  useEffect(() => {
    setTooltipOpen(true);
  }, [value, ticker]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (tooltipOpen && !alwaysShowTooltip) {
      timeout = setTimeout(() => {
        setTooltipOpen(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [tooltipOpen, alwaysShowTooltip]);
  const onValueChange = ([v]: number[]): void => {
    setCurrentIndex(Math.floor(v));
  };
  const defaultTickLeft = leftMinPx + defaultIndex * percentagePx / (steps.length - 1);
  const firstStep = steps?.[0];
  const lastStep = steps?.[steps.length - 1];
  return <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="PotentialValueSlider" data-sentry-source-file="PotentialValueSlider.tsx">
      <div className="flex flex-row w-full px-2 py-4">
        <div className="pr-2 h-full flex items-center justify-center">
          <span className="text-xs text-primary-foreground font-semibold mt-2.5">
            {firstStep?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: firstStep > 10 ? 0 : 2
          })}
          </span>
        </div>
        <button className="h-9 z-0 bg-white rounded-l-md pr-0" onClick={() => {
        if (currentIndex && currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        }
      }}>
          <ChevronLeft className="size-6 text-gray-300" data-sentry-element="ChevronLeft" data-sentry-source-file="PotentialValueSlider.tsx" />
        </button>
        <div className="relative flex w-full touch-none select-none items-center justify-center">
          <div className="relative">
            <div className="absolute -top-5 rotate-180 w-2 h-full z-0" style={{
            left: defaultTickLeft
          }}>
              <TriangleIcon className="size-2 text-white fill-white z-20 -mb-10" data-sentry-element="TriangleIcon" data-sentry-source-file="PotentialValueSlider.tsx" />
            </div>
          </div>
          <div className="w-full">
            <RadixSlider.Root ref={ref} value={currentIndex || currentIndex === 0 ? [currentIndex] : []} onValueChange={onValueChange} min={0} max={steps.length - 1} step={1} className="flex grow cursor-grab touch-none select-none items-center active:cursor-grabbing" data-sentry-element="unknown" data-sentry-source-file="PotentialValueSlider.tsx">
              <RadixSlider.Track asChild data-sentry-element="unknown" data-sentry-source-file="PotentialValueSlider.tsx">
                <div className="relative isolate max-h-full w-full h-9 grow overflow-hidden bg-white">
                  {isMounted && steps.map((_, index) => {
                  const left = leftMinPx + index * percentagePx / (steps.length - 1);
                  const isLow = lowIndex === index;
                  const isHigh = highIndex === index;
                  return <React.Fragment key={index}>
                          <div className={cn("absolute h-full py-1 w-0")} style={{
                      left: index === 0 ? leftMinPx : left
                    }}>
                            <div className={cn("h-full relative", {
                        "bg-gray-200": !isLow && !isHigh,
                        "bg-red-500 z-20": isLow,
                        "bg-green-600": isHigh
                      })} style={{
                        width: "1.5px"
                      }}>
                              {isLow && <>
                                  <div className="absolute top-0 left-0 w-6 h-full bg-gradient-to-r from-red-500/40 to-transparent"></div>
                                  <span className="text-xs text-red-500 font-semibold ml-2 z-20">
                                    52wL
                                  </span>
                                </>}
                              {isHigh && <>
                                  <div className="absolute top-0 right-0 w-6 h-full bg-gradient-to-l from-green-600/40 to-transparent"></div>
                                  <span className="text-xs text-green-600 font-semibold -ml-10 z-20">
                                    52wH
                                  </span>
                                </>}
                            </div>
                          </div>
                        </React.Fragment>;
                })}
                </div>
              </RadixSlider.Track>
              {value && <Tooltip open={tooltipOpen} delayDuration={0}>
                  <TooltipTrigger asChild>
                    <RadixSlider.Thumb asChild>
                      <div className="relative w-12 flex items-center justify-center z-50 outline-none">
                        <div className={cn("h-12 w-12 rounded-md bg-secondary flex items-center justify-center z-50", "shadow-white/25 shadow-[inset_0_1.5px_0_0_rgba(255,255,255,0.25)]")}>
                          <ChevronLeft className="size-8 text-white" />
                          <ChevronRight className="size-8 text-white" />
                        </div>
                      </div>
                    </RadixSlider.Thumb>
                  </TooltipTrigger>
                  <TooltipContent className="bg-white z-50 text-primary shadow-md">
                    <p className="text-lg">
                      {value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: value > 100 ? 0 : 2
                  })}
                    </p>
                    <TooltipArrow className="fill-white" />
                  </TooltipContent>
                </Tooltip>}
            </RadixSlider.Root>
          </div>
        </div>
        <button className="h-9 bg-white rounded-r-md pl-0" onClick={() => {
        if (currentIndex && currentIndex < steps.length - 1) {
          setCurrentIndex(currentIndex + 1);
        }
      }}>
          <ChevronRight className="size-6 text-gray-300" data-sentry-element="ChevronRight" data-sentry-source-file="PotentialValueSlider.tsx" />
        </button>
        <div className="pl-2 h-full flex items-center justify-center">
          <span className="text-xs text-primary-foreground font-semibold mt-2.5">
            {lastStep?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: lastStep > 10 ? 0 : 2
          })}
          </span>
        </div>
      </div>
    </TooltipProvider>;
}
export function PotentialValueSliderWrapped() {
  const router = useRouter();
  const {
    currentIndex,
    navigationIndex,
    defaultIndex
  } = useScenarioSlider();
  const searchParams = useSearchParams();
  const ticker = searchParams.get("ticker");
  useEffect(() => {
    if (navigationIndex !== undefined && navigationIndex !== -1 && navigationIndex === currentIndex && navigationIndex !== defaultIndex) {
      const combinedSearchParams = qs.stringify({
        initialIndex: navigationIndex,
        ticker
      });
      router.push(`/scenario?${combinedSearchParams}`);
    }
  }, [navigationIndex, currentIndex, defaultIndex, router, ticker]);
  return <PotentialValueSlider alwaysShowTooltip data-sentry-element="PotentialValueSlider" data-sentry-component="PotentialValueSliderWrapped" data-sentry-source-file="PotentialValueSlider.tsx" />;
}