"use client";

import { trpc } from "@/providers/TrpcProvider";
import { GetTickerAssignmentReturnType } from "@/server/providers/tickers";
import { createContext, useContext } from "react";
type CurrentPriceContextType = {
  currentPrice: number;
  isLoading: boolean;
  lastMarketDayClosePrice: number | null | undefined;
};
const CurrentPriceContext = createContext<CurrentPriceContextType>({
  currentPrice: 0,
  isLoading: false,
  lastMarketDayClosePrice: undefined
});
export const useCurrentPrice = () => {
  return useContext(CurrentPriceContext);
};
export const CurrentPriceProvider = ({
  children,
  tickerToQuery,
  tickerAssignment: defaultTickerAssignment,
  enabled = true
}: {
  children: React.ReactNode;
  tickerToQuery?: string;
  tickerAssignment?: GetTickerAssignmentReturnType;
  enabled?: boolean;
}) => {
  const {
    data: tickerAssignment
  } = trpc.tickers.getTickerAssignment.useQuery(undefined, {
    enabled: enabled,
    initialData: defaultTickerAssignment,
    refetchOnWindowFocus: true
  });
  const ticker = tickerToQuery ?? tickerAssignment?.ticker;
  const {
    data: lastMarketDayClosePrice
  } = trpc.tickers.getPrevMarketDayClosePrice.useQuery({
    ticker: ticker ?? ""
  }, {
    enabled: enabled && !!ticker
  });
  const {
    data: currentPriceData,
    isLoading
  } = trpc.tickers.getLatestStockPrice.useQuery(ticker ? [ticker] : [], {
    refetchInterval: 5_000,
    enabled: enabled
  });
  const tickerToUse = Object.keys(currentPriceData ?? {})?.[0] ?? ticker;
  const currentPrice = currentPriceData?.[tickerToUse] || 0.0001;
  return <CurrentPriceContext.Provider value={{
    currentPrice,
    isLoading,
    lastMarketDayClosePrice
  }} data-sentry-element="unknown" data-sentry-component="CurrentPriceProvider" data-sentry-source-file="CurrentPriceProvider.tsx">
      {children}
    </CurrentPriceContext.Provider>;
};