"use client";

import { getSharesCalculations } from "@/components/cards/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { RsuBasicRecord } from "@/db/schema";
import { cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import dayjs from "dayjs";
import { Info, Share, Triangle, X } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useMemo, useState } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { useCurrentPrice } from "./CurrentPriceProvider";
import PotentialValueSlider from "./PotentialValueSlider";
import { useScenario } from "./ScenarioProvider";
import { useScenarioSlider } from "./ScenarioSliderProvider";
export default function ScenarioCard({
  rsu
}: {
  rsu: RsuBasicRecord | null;
}) {
  const router = useRouter();
  const {
    priceLikelihood,
    ticker,
    valueMode,
    setValueMode
  } = useScenario();
  const {
    scenarioSelectedValue,
    defaultIndex,
    setCurrentIndex,
    defaultPrice
  } = useScenarioSlider();
  const {
    currentPrice
  } = useCurrentPrice();

  // Fetch salary and upcoming vestings data for total comp calculation
  const {
    data: salary
  } = trpc.compensation.getSalaryByUserId.useQuery();
  const {
    data: grants
  } = trpc.rsu.getRsuGrants.useQuery(undefined, {
    initialData: []
  });
  const [isFlipped, setIsFlipped] = useState(false);
  const {
    ownedRsus: rsuAmount
  } = getSharesCalculations(grants, rsu);
  const priceToShow = scenarioSelectedValue;
  const currentPriceValue = defaultPrice;
  const changeDifference = (priceToShow - currentPriceValue) / currentPriceValue;
  const changeDifferenceValue = changeDifference;
  const totalValueDifference = priceToShow * rsuAmount - currentPrice * rsuAmount;

  // Calculate total compensation values
  const compValue: number = salary?.annualAmount ?? 0;
  const currentYearVestingRsus = grants?.length > 0 ? grants.flatMap(grant => grant.vestings).filter(vesting => vesting?.vestingDate && dayjs(vesting.vestingDate).isAfter(dayjs().endOf("year").subtract(1, "year")) && dayjs(vesting.vestingDate).isBefore(dayjs().startOf("year").add(1, "year"))) : [];
  const totalVestingValue: number = currentYearVestingRsus.reduce((acc, vesting) => acc + (vesting?.vestingAmount ?? 0), 0) ?? 0;

  // Calculate total comp value using scenario price
  const totalCompValue = compValue + totalVestingValue * scenarioSelectedValue;

  // Calculate the difference between scenario and current price total comp
  const currentTotalCompValue = compValue + totalVestingValue * defaultPrice;
  const totalCompDifference = totalCompValue - currentTotalCompValue;
  const data = useMemo(() => {
    const data = [{
      name: "likelihood",
      value: priceLikelihood
    }, {
      name: "not likelihood",
      value: 1 - priceLikelihood
    }];
    return data;
  }, [priceLikelihood]);
  return <div className="flex flex-col p-4 max-w-screen-lg w-full grow mx-auto gap-4" data-sentry-component="ScenarioCard" data-sentry-source-file="ScenarioCard.tsx">
      <div className="flex flex-row justify-between">
        <p className="text-xl">Scenario Explorer</p>
        {rsu && <div className="flex flex-row items-center gap-1">
            <Label htmlFor="valueMode" className="text-xs text-muted-foreground">
              Held RSUs
            </Label>
            <Switch id="valueMode" checked={valueMode} className="data-[state=unchecked]:bg-primary" onCheckedChange={setValueMode} />
            <Label htmlFor="valueMode" className="text-xs text-muted-foreground">
              {dayjs().year()} Total Comp
            </Label>
          </div>}
      </div>
      {isFlipped ? <div className="flex flex-col h-[305px] rounded-lg shadow-sm">
          <div className="w-full flex justify-between items-center px-4 py-2 bg-secondary/20 rounded-t-lg h-14">
            <h2 className="text-sm font-medium line-clamp-2">
              LIKELIHOOD RATING
            </h2>
            <div className="flex flex-row items-center">
              <Link href="/invites" className={cn(buttonVariants({
            variant: "ghost",
            size: "icon"
          }), "text-primary text-xs p-0")}>
                <Share className="size-5" />
              </Link>
              <Button variant="ghost" size="icon" className="text-primary text-xs p-0 -mr-2" onClick={() => setIsFlipped(false)}>
                <X className="size-5" />
              </Button>
            </div>
          </div>
          <div className="p-4 bg-secondary/20 rounded-b-md from-[#EDF8F9] to-[#E0F3F5] bg-gradient-to-b grow">
            <p className="font-bold">Methodology</p>
            <p>
              Based on historical data and our proprietary risk/return models,
              the likelihood value represents the probability of achieving a
              gain or loss that surpasses the target price within the next 90
              days.
            </p>
          </div>
        </div> : <div className="pt-4 px-4 pb-2 rounded-xl bg-primary bg-gradient-to-b from-primary to-secondary/20 shadow-sm">
          <div className="flex flex-row justify-between">
            <div>
              <div className="font-semibold text-xs text-secondary">
                SCENARIO PRICE
              </div>
              <p className="text-3xl text-primary-foreground">
                {priceToShow.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2
            })}{" "}
              </p>

              <p className={cn("text-muted-foreground font-medium flex flex-row items-center gap-1 mt-1", changeDifferenceValue > 0 ? "text-green-400" : "text-red-400", changeDifferenceValue === 0 ? "text-muted-foreground" : "")}>
                {changeDifferenceValue !== 0 && <Triangle className={cn("w-3 h-3", changeDifferenceValue > 0 ? "text-green-400 fill-green-400" : "text-red-400 fill-red-400 rotate-180")} />}
                {changeDifferenceValue.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
              </p>
            </div>
            {rsu ? <div className="text-right">
                <div className="font-semibold text-xs text-secondary">
                  {valueMode ? "SCENARIO TOTAL COMP" : "SCENARIO RSU VALUE"}
                </div>
                <p className="text-3xl text-primary-foreground">
                  {valueMode ? totalCompValue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0
            }) : (priceToShow * rsuAmount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0
            })}
                </p>

                <p className={cn("text-muted-foreground font-medium flex flex-row-reverse items-center gap-1 mt-1", valueMode ? totalCompDifference > 0 ? "text-green-400" : "text-red-400" : totalValueDifference > 0 ? "text-green-400" : "text-red-400", (valueMode ? totalCompDifference : totalValueDifference) === 0 ? "text-muted-foreground" : "")}>
                  {valueMode ? totalCompDifference.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) : totalValueDifference.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
                  {(valueMode ? totalCompDifference : totalValueDifference) !== 0 && <Triangle className={cn("w-3 h-3", valueMode ? totalCompDifference > 0 ? "text-green-400 fill-green-400" : "text-red-400 fill-red-400 rotate-180" : totalValueDifference > 0 ? "text-green-400 fill-green-400" : "text-red-400 fill-red-400 rotate-180")} />}
                </p>
              </div> : <div className="text-right text-secondary">
                <div className="font-semibold text-xs text-secondary">
                  SCENARIO RSU VALUE
                </div>
                <Link href="/sign-up" className="text-3xl underline">
                  Join
                </Link>
                <p>to see yours</p>
              </div>}
          </div>

          <div className="flex flex-col items-center -mt-10">
            <div className="relative">
              <PieChart width={320} height={160} className="-ml-3">
                <Pie data={data} cx={160} cy={160} startAngle={180} endAngle={0} innerRadius={85} outerRadius={100} stroke="transparent" paddingAngle={0} dataKey="value" animationDuration={0}>
                  <Cell fill={getDialColor(data?.[0]?.value, "dark")} />
                  <Cell fill={"#FFFFFF"} />
                </Pie>
              </PieChart>

              <div className="text-4xl text-primary-foreground absolute bottom-0 text-center left-0 right-0">
                {/* TODO: margin is uncertain here */}
                <span className="tracking-tighter w-full">
                  {data[0].value.toLocaleString("en-US", {
                style: "percent",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
                </span>
              </div>
            </div>
            <p className="text-xs text-primary-foreground pt-2 font-semibold">
              LIKELIHOOD IN THE NEXT 90 DAYS
            </p>
          </div>
          <Separator className="my-2 bg-secondary/35" />
          <div className="flex flex-row justify-between items-center">
            <Button variant="link" className={cn("text-primary-foreground text-xs p-0")} onClick={() => {
          setCurrentIndex(defaultIndex);
        }}>
              Reset
            </Button>

            <div className="flex flex-row items-center gap-1">
              <Link href="/invites" className={cn(buttonVariants({
            variant: "ghost",
            size: "icon"
          }), "text-primary-foreground text-xs p-0")}>
                <Share className="size-5" />
              </Link>
              <Button variant="ghost" size="icon" className="text-primary-foreground text-xs p-0" onClick={() => setIsFlipped(true)}>
                <Info className="size-5" />
              </Button>
            </div>
          </div>
          <div className="hidden md:block">
            <PotentialValueSlider />
          </div>
        </div>}
    </div>;
}
export const getDialColor = (value?: number, background: "dark" | "light" = "dark") => {
  const GRAY = "#808080";
  const COLORS = background === "dark" ? ["#20DD00", "#FFB800", "#FF554D"] : ["#18A800", "#FFB800", "#E00f2f"];
  if (!value) return GRAY;
  if (value <= 1 / 3) return COLORS[2];
  if (value >= 2 / 3) return COLORS[0];
  return COLORS[1];
};