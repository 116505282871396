"use client";

import { GetAnalystEstimatesReturnType } from "@/lib/ontrade";
import { trpc } from "@/providers/TrpcProvider";
import { GetRiskRewardChartDataReturnType, GetScenarioPotentialsReturnType, GetSliderStepsReturnType } from "@/server/providers/scenarios";
import { GetEPSForPriceReturnType, GetMarketComparisonReturnType, GetMarketStatusReturnType } from "@/server/providers/tickers";
import { createContext, useContext, useState } from "react";
import ScenarioSliderProvider, { useScenarioSlider } from "./ScenarioSliderProvider";
type ScenarioContextType = {
  ticker: string;
  lastMarketOpenPrice: number | undefined | null;
  vestedOnly: boolean;
  setVestedOnly: (vestedOnly: boolean) => void;
  priceLikelihood: number;
  riskRewardChartData: GetRiskRewardChartDataReturnType | undefined;
  isRiskRewardChartDataLoading: boolean;
  percentChange: number;
  negativePercentChange: number;
  negativeScenarioSelectedValue: number;
  scenarioPotentials: GetScenarioPotentialsReturnType | undefined;
  isScenarioPotentialsLoading: boolean;
  days: number;
  setDays: (days: number) => void;
  marketComparison: GetMarketComparisonReturnType | undefined;
  isMarketComparisonLoading: boolean;
  marketStatus: GetMarketStatusReturnType | undefined;
  isMarketStatusLoading: boolean;
  epsData: GetEPSForPriceReturnType | undefined;
  epsDataLoading: boolean;
  analystEstimates: GetAnalystEstimatesReturnType | undefined | null;
  isAnalystEstimatesLoading: boolean;
  isScenarioLoading: boolean;
  valueMode: boolean;
  setValueMode: (valueMode: boolean) => void;
  refetchLastMarketOpenPrice: () => void;
  refetchEpsData: () => void;
  refetchRiskRewardChartData: () => void;
  refetchScenarioPotentials: () => void;
  refetchMarketStatus: () => void;
  refetchAnalystEstimates: () => void;
  refetchMarketComparison: () => void;
};
const ScenarioContext = createContext<ScenarioContextType>({} as ScenarioContextType);
export default function ScenarioProvider({
  children,
  scenarioPotentials: defaultScenarioPotentials,
  marketComparison: defaultMarketComparison,
  marketStatus: defaultMarketStatus,
  analystEstimates: defaultAnalystEstimates,
  defaultSteps,
  initialIndex,
  ticker: defaultTicker,
  preserveIndex = false,
  lastScenarioIndex
}: {
  children: React.ReactNode;
  defaultSteps: GetSliderStepsReturnType | undefined;
  scenarioPotentials: GetScenarioPotentialsReturnType | undefined;
  marketComparison: GetMarketComparisonReturnType | undefined;
  marketStatus: GetMarketStatusReturnType | undefined;
  analystEstimates: GetAnalystEstimatesReturnType | undefined | null;
  ticker: string;
  initialIndex?: number;
  preserveIndex?: boolean;
  lastScenarioIndex?: number;
}) {
  const {
    data: tickerAssignment
  } = trpc.tickers.getTickerAssignment.useQuery();
  const ticker = tickerAssignment?.ticker ?? defaultTicker;
  const {
    data: lastMarketOpenPrice,
    refetch: refetchLastMarketOpenPrice
  } = trpc.tickers.getPrevMarketDayClosePrice.useQuery({
    ticker
  }, {});

  // Use the ScenarioSliderProvider for slider-related state
  return <ScenarioSliderProvider ticker={ticker} defaultSteps={defaultSteps} initialIndex={initialIndex} lastScenarioIndex={lastScenarioIndex} preserveIndex={preserveIndex} skipDefaultIndex forceInitialIndex data-sentry-element="ScenarioSliderProvider" data-sentry-component="ScenarioProvider" data-sentry-source-file="ScenarioProvider.tsx">
      <ScenarioProviderInner scenarioPotentials={defaultScenarioPotentials} marketComparison={defaultMarketComparison} marketStatus={defaultMarketStatus} analystEstimates={defaultAnalystEstimates} lastMarketOpenPrice={lastMarketOpenPrice} refetchLastMarketOpenPrice={refetchLastMarketOpenPrice} ticker={ticker} data-sentry-element="ScenarioProviderInner" data-sentry-source-file="ScenarioProvider.tsx">
        {children}
      </ScenarioProviderInner>
    </ScenarioSliderProvider>;
}
function ScenarioProviderInner({
  children,
  scenarioPotentials: defaultScenarioPotentials,
  marketComparison: defaultMarketComparison,
  marketStatus: defaultMarketStatus,
  analystEstimates: defaultAnalystEstimates,
  lastMarketOpenPrice,
  refetchLastMarketOpenPrice,
  ticker
}: {
  children: React.ReactNode;
  scenarioPotentials: GetScenarioPotentialsReturnType | undefined;
  marketComparison: GetMarketComparisonReturnType | undefined;
  marketStatus: GetMarketStatusReturnType | undefined;
  analystEstimates: GetAnalystEstimatesReturnType | undefined | null;
  lastMarketOpenPrice: number | undefined | null;
  refetchLastMarketOpenPrice: () => void;
  ticker: string;
}) {
  const {
    scenarioSelectedValue,
    defaultPrice,
    priceLikelihoods
  } = useScenarioSlider();
  const [vestedOnly, setVestedOnly] = useState(true);
  const [valueMode, setValueMode] = useState(false);
  const {
    data: epsData,
    isFetching: isEPSDataLoading,
    refetch: refetchEpsData
  } = trpc.tickers.getEPSForPrice.useQuery({
    ticker,
    price: scenarioSelectedValue
  }, {});
  const priceLikelihood = priceLikelihoods.find(likelihood => likelihood.step === scenarioSelectedValue)?.likelihood ?? 0;
  const percentChange = (scenarioSelectedValue - defaultPrice) / defaultPrice;
  const negativeScenarioSelectedValue = defaultPrice * (1 - percentChange) < 0 ? 0 : defaultPrice * (1 - percentChange);
  const negativePercentChange = (negativeScenarioSelectedValue - defaultPrice) / defaultPrice;
  const {
    data: riskRewardChartData,
    isFetching: isRiskRewardChartDataLoading,
    refetch: refetchRiskRewardChartData
  } = trpc.tickers.getRiskRewardChartData.useQuery({
    priceAbove: scenarioSelectedValue > defaultPrice ? scenarioSelectedValue : negativeScenarioSelectedValue,
    priceBelow: scenarioSelectedValue < defaultPrice ? scenarioSelectedValue : negativeScenarioSelectedValue,
    ticker
  }, {
    trpc: {
      context: {
        skipBatch: true
      }
    }
  });
  const [days, setDays] = useState(90);
  const {
    data: scenarioPotentials,
    isFetching: isScenarioPotentialsLoading,
    refetch: refetchScenarioPotentials
  } = trpc.tickers.getScenarioPotentials.useQuery({
    ticker,
    days
  }, {
    initialData: defaultScenarioPotentials,
    trpc: {
      context: {
        skipBatch: true
      }
    }
  });
  const {
    data: marketStatus,
    isFetching: isMarketStatusLoading,
    refetch: refetchMarketStatus
  } = trpc.tickers.getMarketStatus.useQuery(undefined, {
    refetchInterval: 5 * 60 * 1000,
    initialData: defaultMarketStatus
  });
  const {
    data: analystEstimates,
    isFetching: isAnalystEstimatesLoading,
    refetch: refetchAnalystEstimates
  } = trpc.tickers.getAnalystEstimates.useQuery({
    ticker
  }, {
    initialData: defaultAnalystEstimates ?? undefined
  });
  const {
    data: marketComparison,
    isFetching: isMarketComparisonLoading,
    refetch: refetchMarketComparison
  } = trpc.tickers.getMarketComparison.useQuery({
    ticker
  }, {
    initialData: defaultMarketComparison
  });
  const isScenarioLoading = isRiskRewardChartDataLoading || isEPSDataLoading || isMarketStatusLoading || isMarketComparisonLoading || isAnalystEstimatesLoading || isScenarioPotentialsLoading;
  return <ScenarioContext.Provider value={{
    ticker,
    lastMarketOpenPrice,
    vestedOnly,
    setVestedOnly,
    priceLikelihood,
    riskRewardChartData,
    isRiskRewardChartDataLoading,
    percentChange,
    negativePercentChange,
    negativeScenarioSelectedValue,
    scenarioPotentials,
    isScenarioPotentialsLoading,
    days,
    setDays,
    marketComparison,
    isMarketComparisonLoading,
    marketStatus,
    isMarketStatusLoading,
    epsData,
    epsDataLoading: isEPSDataLoading,
    analystEstimates,
    isAnalystEstimatesLoading,
    valueMode,
    setValueMode,
    refetchLastMarketOpenPrice,
    refetchEpsData,
    refetchRiskRewardChartData,
    refetchScenarioPotentials,
    refetchMarketStatus,
    refetchAnalystEstimates,
    refetchMarketComparison,
    isScenarioLoading
  }} data-sentry-element="unknown" data-sentry-component="ScenarioProviderInner" data-sentry-source-file="ScenarioProvider.tsx">
      {children}
    </ScenarioContext.Provider>;
}
export const useScenario = () => {
  const context = useContext(ScenarioContext);
  if (!context) {
    throw new Error("useScenario must be used within a ScenarioProvider");
  }
  return context;
};