"use client";

import { trpc } from "@/providers/TrpcProvider";
import { GetSliderStepsReturnType } from "@/server/providers/scenarios";
import Cookies from "js-cookie";
import { usePostHog } from "posthog-js/react";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

// Define the context type with only the slider-related values
interface ScenarioSliderContextType {
  ticker: string;
  scenarioSelectedValue: number;
  currentIndex: number | undefined;
  setCurrentIndex: (index: number) => void;
  slideSteps: GetSliderStepsReturnType | undefined;
  defaultIndex: number;
  debouncedIndex: number | undefined;
  defaultPrice: number;
  refetchSlideSteps?: () => void;
  navigationIndex: number | undefined;
  priceLikelihoods: {
    step: number;
    likelihood: number;
  }[];
  isPriceLikelihoodLoading: boolean;
  priceLikelihood: number;
}

// Create the context
const ScenarioSliderContext = createContext<ScenarioSliderContextType>({} as ScenarioSliderContextType);

// Create a hook to use the context
export const useScenarioSlider = () => {
  const context = useContext(ScenarioSliderContext);
  if (!context) {
    throw new Error("useScenarioSlider must be used within a ScenarioSliderProvider");
  }
  return context;
};

// Define the props for the ScenarioSliderProvider
interface ScenarioSliderProviderProps {
  children: ReactNode;
  ticker: string;
  defaultSteps?: GetSliderStepsReturnType;
  initialIndex?: number;
  skipDefaultIndex?: boolean;
  preserveIndex?: boolean;
  lastScenarioIndex?: number;
  forceInitialIndex?: boolean;
}

// Create the ScenarioSliderProvider component
export default function ScenarioSliderProvider({
  children,
  ticker,
  defaultSteps,
  initialIndex: defaultInitialIndex,
  preserveIndex = false,
  skipDefaultIndex = false,
  lastScenarioIndex: lastScenarioIndexProp = 0,
  forceInitialIndex = false
}: ScenarioSliderProviderProps) {
  const posthog = usePostHog();

  // Fetch slider steps data
  const {
    data: slideSteps,
    refetch: refetchSlideSteps
  } = trpc.tickers.getSliderSteps.useQuery({
    ticker
  }, {
    initialData: defaultSteps
  });
  const defaultIndex = slideSteps?.defaultIndex ?? 0;
  const defaultPrice = slideSteps?.steps[defaultIndex] ?? 0;
  const {
    data: priceLikelihoodAbove,
    isFetching: isPriceLikelihoodLoadingAbove
  } = trpc.tickers.getPriceLikelihood.useQuery({
    prices: slideSteps?.steps.filter(step => step > defaultPrice) ?? [],
    direction: "above",
    days: 90,
    ticker
  }, {
    refetchOnWindowFocus: false,
    trpc: {
      context: {
        skipBatch: true
      }
    }
  });
  const {
    data: priceLikelihoodBelow,
    isFetching: isPriceLikelihoodLoadingBelow
  } = trpc.tickers.getPriceLikelihood.useQuery({
    prices: slideSteps?.steps.filter(step => step < defaultPrice) ?? [],
    direction: "below",
    days: 90,
    ticker
  }, {
    refetchOnWindowFocus: false,
    trpc: {
      context: {
        skipBatch: true
      }
    }
  });
  const priceLikelihoods = useMemo(() => {
    if (priceLikelihoodAbove && priceLikelihoodBelow) {
      return slideSteps?.steps.map(step => {
        return {
          step: step,
          likelihood: step > defaultPrice ? priceLikelihoodAbove?.likelihoods.find(likelihood => likelihood.target_price === step)?.likelihood ?? 0 : priceLikelihoodBelow?.likelihoods.find(likelihood => likelihood.target_price === step)?.likelihood ?? 0
        };
      }) ?? [];
    }
    return [];
  }, [priceLikelihoodAbove, priceLikelihoodBelow, slideSteps, defaultPrice]);
  const isPriceLikelihoodLoading = isPriceLikelihoodLoadingAbove || isPriceLikelihoodLoadingBelow;
  const {
    filteredSlideSteps,
    initialIndex
  } = useMemo(() => {
    if (!slideSteps) {
      return {
        filteredSlideSteps: slideSteps,
        initialIndex: forceInitialIndex ? priceLikelihoods.findLastIndex(likelihood => likelihood.likelihood >= 0.7) : defaultIndex
      };
    }
    if (skipDefaultIndex) {
      const initialStep = slideSteps?.steps[defaultInitialIndex ?? defaultIndex];
      const filteredSlideSteps = slideSteps?.steps.filter((_, index) => index !== defaultIndex).map(step => ({
        step,
        likelihood: priceLikelihoods.find(likelihood => likelihood.step === step)?.likelihood ?? 0
      }));
      const newInitialIndex = forceInitialIndex ? filteredSlideSteps?.findLastIndex(likelihood => likelihood.likelihood >= 0.7) : filteredSlideSteps?.findIndex(step => step.step === initialStep);
      return {
        filteredSlideSteps: {
          ...slideSteps,
          steps: filteredSlideSteps?.map(step => step.step) ?? []
        },
        initialIndex: newInitialIndex ?? defaultIndex
      };
    }
    return {
      filteredSlideSteps: {
        ...slideSteps,
        steps: slideSteps?.steps ?? []
      },
      initialIndex: forceInitialIndex ? priceLikelihoods.findLastIndex(likelihood => likelihood.likelihood <= 0.7) : defaultInitialIndex ?? defaultIndex
    };
  }, [priceLikelihoods, slideSteps, skipDefaultIndex, defaultIndex, defaultInitialIndex, forceInitialIndex]);

  // Get the last scenario index from cookies if preserveIndex is true
  const lastScenarioIndex = useMemo(() => {
    if (typeof window === "undefined" || forceInitialIndex) return initialIndex > -1 ? initialIndex : lastScenarioIndexProp || defaultIndex;
    const cookieValue = Cookies.get(`${ticker}:lastScenarioIndex`);
    try {
      return parseInt(cookieValue ?? defaultIndex.toString());
    } catch (e) {
      return defaultIndex;
    }
  }, [ticker, defaultIndex, lastScenarioIndexProp, forceInitialIndex, initialIndex]);

  // Set the current index based on initialIndex or lastScenarioIndex
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);

  // Update currentIndex when ticker or defaultIndex changes
  useEffect(() => {
    if (priceLikelihoods.length === 0) return;
    setCurrentIndex(initialIndex && initialIndex > -1 && initialIndex !== defaultIndex ? initialIndex : preserveIndex ? lastScenarioIndex : defaultIndex);
  }, [initialIndex, preserveIndex, lastScenarioIndex, defaultIndex, priceLikelihoods]);

  // Debounce the index to prevent too many API calls
  const [debouncedIndex] = useDebounce(currentIndex, 250);
  const navigationIndex = useMemo(() => {
    if (skipDefaultIndex) {
      return slideSteps?.steps.findIndex(step => step === filteredSlideSteps?.steps[currentIndex ?? 0]) ?? debouncedIndex;
    }
    return debouncedIndex;
  }, [skipDefaultIndex, currentIndex, debouncedIndex, filteredSlideSteps, slideSteps]);

  // Calculate the selected value based on the current index
  const scenarioSelectedValue = filteredSlideSteps?.steps?.[debouncedIndex ?? 0] ?? 0;

  // Track scenario value changes with PostHog
  useEffect(() => {
    if (debouncedIndex !== defaultIndex) {
      posthog.capture("scenario_value_changed", {
        value: scenarioSelectedValue,
        ticker
      });
    }
  }, [debouncedIndex, defaultIndex, scenarioSelectedValue, ticker, posthog]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const filteredSlideStepsValue = filteredSlideSteps?.steps[currentIndex ?? 0];
      const unfilteredIndex = slideSteps?.steps.findIndex(step => step === filteredSlideStepsValue);
      if (unfilteredIndex === -1 || unfilteredIndex === undefined || currentIndex === undefined) {
        return;
      }
      Cookies.set(`${ticker}:lastScenarioIndex`, unfilteredIndex?.toString() ?? (currentIndex ?? 0).toString(), {
        expires: 30,
        // Cookie expires in 30 days
        path: "/",
        // Cookie is available across the entire site
        sameSite: "strict" // Enhances security
      });
    }
  }, [currentIndex, ticker, filteredSlideSteps, slideSteps]);
  const priceLikelihood = priceLikelihoods.find(likelihood => likelihood.step === scenarioSelectedValue)?.likelihood ?? 0;

  // Provide the context value
  const contextValue: ScenarioSliderContextType = {
    ticker,
    scenarioSelectedValue,
    currentIndex,
    setCurrentIndex,
    slideSteps: filteredSlideSteps,
    navigationIndex,
    defaultIndex,
    debouncedIndex,
    defaultPrice,
    refetchSlideSteps,
    priceLikelihoods,
    isPriceLikelihoodLoading,
    priceLikelihood
  };
  return <ScenarioSliderContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="ScenarioSliderProvider" data-sentry-source-file="ScenarioSliderProvider.tsx">
      {children}
    </ScenarioSliderContext.Provider>;
}